<template>
  <base-layout>
    <add-edit-service-area />

    <content-section :spacing="false">
      <div class="content-spacing">
        <!-- <div class="grid grid-cols-1 mb-10 md:grid-cols-2"> -->
        <div class="grid grid-cols-1 mb-10">
          <div class="">
            <title-plus title="Service Area" @plus="add" />
          </div>

          <!-- <div class="flex md:justify-end">
            <date-range-picker
              @apply-date="handleApplyDate($event)"
              @cancel-date="handleCancelDate($event)"
            />
          </div> -->
        </div>

        <div
          class="flex flex-col mt-4 mb-10 space-x-0 space-y-4 md:space-x-4 md:space-y-0 md:items-center md:flex-row"
        >
          <card-tax-stat
            title="Total Service Areas"
            :value="indexMetaData.summary.total"
            variant="green"
          />
          <card-tax-stat
            title="Vehicles Assigned"
            :value="indexMetaData.summary.vehicles_assigned"
            variant="yellow"
          />
          <card-tax-stat
            title="Vehicles Inside"
            :value="indexMetaData.summary.vehicles_inside"
            variant="blue"
          />
          <card-tax-stat
            title="Vehicles Outside"
            :value="indexMetaData.summary.vehicles_outside"
            variant="red"
          />
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2">
          <div>
            <oto-search v-model="search" />
          </div>
          <div class="flex md:justify-end ">
            <export-as-dropdown />
          </div>
        </div>
      </div>

      <fsTableContainer class="vld-parent">
        <loading :active.sync="isLoading" :is-full-page="false" />
        <fsTable>
          <fsTableHead>
            <fsTableHeadItem
              v-for="(item, index) in tableHeaders"
              :key="`fs-table-header-${index}`"
              :item="item"
              @apply-sorting="handleSorting($event)"
            />
          </fsTableHead>

          <fsTableRow
            v-for="(item, index) in indexData"
            :key="`fs-table-row-${index}`"
          >
            <fsTableRowItem>
              <router-link
                class="font-bold text-blue-600 capitalize"
                :to="{
                  name: 'ViewServiceArea',
                  params: { id: item.id },
                }"
              >
                #{{ item.id | onlyLastFive }}
              </router-link>
            </fsTableRowItem>

            <fsTableRowItem>
              <router-link
                class="font-bold text-blue-600 capitalize"
                :to="{
                  name: 'ViewServiceArea',
                  params: { id: item.id },
                }"
              >
                {{ item.name }}
              </router-link>
            </fsTableRowItem>

            <fsTableRowItem>
              {{ item.fleet ? item.fleet.name : '--' }}
            </fsTableRowItem>

            <fsTableRowItem>{{ item.vehicles_inside }}</fsTableRowItem>

            <fsTableRowItem>
              <x-status
                :variant="item.is_active ? 'green' : 'orange'"
                :text="item.is_active ? 'Active' : 'Inactive'"
                profile="fleet"
              />
            </fsTableRowItem>

            <fsTableRowItem>
              <div class="flex items-center">
                <oto-edit-icon @click="edit(item)" />
                <MoreActionsDropdown
                  :key="`more-actions-${index}`"
                  :type="`serviceArea`"
                  :data="item"
                  @change="handleAction('change', index, $event)"
                  @delete="handleAction('delete', index, $event)"
                />
              </div>
            </fsTableRowItem>
          </fsTableRow>
        </fsTable>

        <fs-table-foot
          :total-items="indexMetaData.count.total"
          :pagination-summary="paginationSummary"
        />
      </fsTableContainer>
    </content-section>
  </base-layout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout.vue'
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import OtoEditIcon from '@/components/ui/OtoEditIcon'
// import DateRangePicker from "@/components/picker/date-range/DateRangePicker";
import OtoSearch from '@/components/ui/OtoSearch'
import XStatus from '@/components/badge/XStatus'
import ExportAsDropdown from '@/components/dropdown/ExportAsDropdown'

import isLoadingMixin from '@/mixins/isLoadingMixin'
import queryStringMixin from '@/mixins/queryStringMixin'
import queryStringSearchMixin from '@/mixins/queryStringSearchMixin'
import queryStringFiltersMixin from '@/mixins/queryStringFiltersMixin'
import getIndexDataMixin from '@/mixins/getIndexDataMixin'
import fsTableUiMixin from '@/mixins/fsTableUiMixin'

import { ServiceAreaConfig } from '@/config/ServiceAreaConfig'
import { EventBus } from '@/utils/EventBus'

import CardTaxStat from '@/components/cards/TaxStat'
import AddEditServiceArea from './AddEditServiceArea'

export default {
  name: 'ServiceAreas',
  mixins: [
    isLoadingMixin,
    queryStringMixin,
    queryStringSearchMixin,
    queryStringFiltersMixin,
    getIndexDataMixin,
    fsTableUiMixin,
  ],
  components: {
    BaseLayout,
    ContentSection,
    TitlePlus,
    OtoEditIcon,
    OtoSearch,
    // DateRangePicker,
    CardTaxStat,
    XStatus,
    ExportAsDropdown,
    MoreActionsDropdown: () =>
      import('@/composites/geofence/shared/MoreActionsDropdown'),
    AddEditServiceArea,
  },
  data() {
    return {
      indexMetaData: {
        summary: {
          total: 0,
        },
        count: {
          total: 0,
        },
      },
      indexDataEndpoint: ServiceAreaConfig.api.index,
      tableHeaders: [
        {
          name: 'ID',
          width: '10%',
          field: 'id',
          sort: null,
          order: '',
        },
        {
          name: 'Name',
          width: '20%',
          field: 'name',
          sort: true,
          order: '',
        },
        {
          name: 'Assigned Fleet',
          width: '15%',
          field: 'fleet',
          sort: true,
          order: '',
        },
        {
          name: 'Vehicles Inside',
          width: '15%',
          field: 'vehicles_inside',
          sort: null,
          order: '',
        },
        {
          name: 'Status',
          width: '10%',
          field: 'is_active',
          sort: true,
          order: '',
        },
        {
          name: 'Actions',
          width: '10%',
          field: null,
          sort: null,
          order: '',
        },
      ],
    }
  },
  async mounted() {
    // todo: remove the event listener
    // todo: create a refresh index data mixin ?
    window.addEventListener(ServiceAreaConfig.events.refresh, () => {
      this.getIndexData(this.getQueryString)
    })
  },
  methods: {
    add: function() {
      EventBus.$emit(ServiceAreaConfig.events.editingData, {
        // enable status-switch by default
        should_throttle: true,
      })
      dispatchEvent(new Event(ServiceAreaConfig.events.sorToggle))
    },
    edit: async function(item) {
      var serviceAreaDetails = await this.$http
        .get(ServiceAreaConfig.api.details(item.id))
        .then((res) => res.data)
        .catch((err) => console.log(err))
      if (serviceAreaDetails == undefined) return

      delete serviceAreaDetails.created_at
      delete serviceAreaDetails.updated_at
      delete serviceAreaDetails.created_by
      delete serviceAreaDetails.organization

      serviceAreaDetails.existing_fleet = serviceAreaDetails.fleet
      serviceAreaDetails.fleet = serviceAreaDetails.fleet
        ? serviceAreaDetails.fleet.id
        : null
      serviceAreaDetails.speed_limit = serviceAreaDetails.speed_limit.id
      //made by guang
      // if (serviceAreaDetails.fleet == null) {
      //   serviceAreaDetails.fleet = -1;
      // } else {
      //   serviceAreaDetails.fleet = serviceAreaDetails.fleet.id;
      // }

      // if (serviceAreaDetails.speed_limit == null)
      //   serviceAreaDetails.speed_limit = null;
      // else serviceAreaDetails.speed_limit = serviceAreaDetails.speed_limit.id;
      //
      //serviceAreaDetails.fleet = serviceAreaDetails.fleet.id;
      //serviceAreaDetails.speed_limit = serviceAreaDetails.speed_limit.id;
      EventBus.$emit(ServiceAreaConfig.events.editingData, serviceAreaDetails)
      dispatchEvent(new Event(ServiceAreaConfig.events.sorToggle))
    },
    getActionsEndpoint(id) {
      return ServiceAreaConfig.api.update(id)
    },
    handleAction(type, index, data) {
      if (type === 'change') {
        this.indexData[index].is_active = data.is_active
        const activeInactive = data.is_active === true ? 'active' : 'inactive'
        const activatedDeactivated =
          data.is_active === true ? 'activated' : 'deactivated'

        this.$notify({
          type: `success`,
          group: `generic`,
          title: `Service area ${activatedDeactivated}`,
          text: `${data.name} service area is now ${activeInactive}`,
        })

        return
      }

      if (type === 'delete') {
        const name = this.indexData[index].name || '--'
        this.getIndexData(this.getQueryString)

        this.$notify({
          type: `success`,
          group: `generic`,
          title: `Service area deleted`,
          text: `${name} service area has been deleted`,
        })

        return
      }
    },
  },
}
</script>

<style></style>
