<template>
  <slide-over-right :id="sorId" :full-width="true">
    <loading :active.sync="isLoading"></loading>
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2">
        <div class="flex items-center pl-8 mt-6 md-max:pl-4">
          <div
            class="items-center justify-center cursor-pointer back-button"
            @click="goBack"
          >
            <i class="fas fa-arrow-left"></i>
          </div>
          <h2 class="ml-3 font-semibold text-gray-900 text-22px">
            {{ titleText }}
          </h2>
        </div>

        <div v-if="errors.length > 0" class="pl-6 text-red-500">
          Error returned from server
        </div>

        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(confirmSubmit)" class="px-6">
            <div class="grid grid-cols-1 gap-2 mt-4 lg:grid-cols-2">
              <text-input
                rules="required"
                label="Area name"
                v-model="form.name"
              />
              <ValidationProvider
                name="Fleet"
                rules="required"
                v-slot="{ errors }"
              >
                <input-label :error="errors[0]" text="Assigned Fleet *" />
                <t-rich-select
                  name="options"
                  :options="serviceAreaFleets"
                  valueAttribute="id"
                  textAttribute="name"
                  :hideSearchBox="false"
                  :variant="{ danger: !!errors[0] }"
                  v-model="form.fleet"
                  v-on:input="onChangeFleet"
                />
                <div class="">
                  <input-error-item :message="errors[0]" />
                </div>
              </ValidationProvider>
            </div>

            <PlacesAutocomplete
              :label="`Search for a location`"
              :disabled="!!!form.fleet"
              :placeholder="
                !!form.fleet === false
                  ? 'Select a fleet first'
                  : 'Enter address / location'
              "
              @update:place="onUpdatingPlace"
              @clear:place="() => (place = null)"
            />

            <div class="grid grid-cols-1 gap-2 mt-6 lg:grid-cols-2">
              <ValidationProvider
                name="Speed Limit"
                rules="required"
                v-slot="{ errors }"
              >
                <input-label :error="errors[0]" text="Speed Limit *" />
                <t-rich-select
                  name="options"
                  :options="serviceAreaSpeedLimits"
                  valueAttribute="id"
                  textAttribute="speed_choice"
                  :hideSearchBox="true"
                  :variant="{ danger: !!errors[0] }"
                  v-model="form.speed_limit"
                />
                <div class="">
                  <input-error-item :message="errors[0]" />
                </div>
              </ValidationProvider>

              <text-input
                label="Type"
                v-model="mapDrawingMode"
                :disabled="true"
              />
            </div>

            <div class="w-full">
              <input-label text="Note" />
              <t-textarea
                name="serviceAreaDescription"
                v-model="form.description"
              />
              <input-error-item />
            </div>

            <section>
              <input-label
                class="my-2 font-bold "
                text="Vehicle Goes Outside Of Boundary Should"
              />
              <div class="grid grid-cols-1 gap-2 mt-4 lg:grid-cols-2">
                <div
                  :class="
                    form.should_throttle == true
                      ? 'toggle-button-active flex items-center justify-center'
                      : 'toggle-button flex items-center justify-center'
                  "
                >
                  <div class="grid w-3/5">
                    <span class="toggle-title">Throttle</span>
                  </div>
                  <div class="w-2/5">
                    <div class="flex items-center">
                      <t-toggle
                        variant="success"
                        :checked="!!form.should_throttle"
                        v-model="form.should_throttle"
                      />
                      <span class="ml-2 text-sm font-semibold">{{
                        form.should_throttle ? 'ON' : 'OFF'
                      }}</span>
                    </div>
                  </div>
                </div>

                <div
                  :class="
                    form.should_trigger
                      ? 'toggle-button-active flex items-center justify-center'
                      : 'toggle-button flex items-center justify-center'
                  "
                >
                  <div class="grid w-3/5">
                    <span class="toggle-title">Trigger Alarm</span>
                  </div>
                  <div class="w-2/5">
                    <div class="flex items-center">
                      <t-toggle
                        variant="success"
                        :checked="!!form.should_trigger"
                        v-model="form.should_trigger"
                      />
                      <span class="ml-2 text-sm font-semibold">{{
                        form.should_trigger ? 'ON' : 'OFF'
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <button type="submit" ref="submitButton" class="hidden">
              Save
            </button>
          </form>
        </ValidationObserver>

        <div class="flex items-center justify-end pr-6 mt-6">
          <slide-over-right-trigger :id="sorId">
            <anchor-button variant="secondary">Cancel</anchor-button>
          </slide-over-right-trigger>
          <t-button type="submit" @click="submit()" class="ml-3">Save</t-button>
        </div>
      </div>

      <div class="w-full mt-6 md:w-1/2 md:mt-0">
        <gmap-map
          ref="map"
          :zoom="zoom"
          :center="center"
          map-type-id="terrain"
          style="width: 100%; height: 100vh;"
        >
          <!-- <gmap-marker :key="index" v-for="(m, index) in markers" :position="m.position" :clickable="true" :draggable="true" @click="center=m.position" /> -->
        </gmap-map>
      </div>
    </div>
  </slide-over-right>
</template>

<script>
import xMan from '@/utils/xMan'
import { EventBus } from '@/utils/EventBus'
// SubscriptionConfig
import { ServiceAreaConfig } from '@/config/ServiceAreaConfig'
import { DropdownConfig } from '@/config/DropdownConfig'
import { FleetConfig } from '@/config/FleetConfig'
import SlideOverRight from '@/components/modals/SlideOverRight'
import SlideOverRightTrigger from '@/components/modals/SlideOverRightTrigger'
import AnchorButton from '@/components/form/AnchorButton'
import TextInput from '@/components/form/TextInput'
import InputLabel from '@/components/form/InputLabel'
import { gmapApi } from 'vue2-google-maps'
// import { GooglePlacesAutocomplete } from 'vue-better-google-places-autocomplete'

export default {
  name: 'AddServiceArea',
  components: {
    SlideOverRight,
    SlideOverRightTrigger,
    AnchorButton,
    TextInput,
    InputLabel,
    // GooglePlacesAutocomplete,
    PlacesAutocomplete: () => import('@/components/map/PlacesAutocomplete'),
  },
  data() {
    return {
      isLoading: false,
      serviceAreaFleets: [],
      serviceAreaSpeedLimits: [],
      item: {},
      form: {
        should_trigger: true,
        should_throttle: true,
        coords: null,
      },
      center: {
        lat: 10,
        lng: 10,
      },
      errors: [],
      sorId: ServiceAreaConfig.events.sorId,
      scrollwheel: true,
      zoom: 16,
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: true,
      streetViewControl: false,
      rotateControl: true,
      fullscreenControl: true,
      markers: [{}],
      edited: null,
      map: null,
      drawingManager: null,
      mapDrawingMode: 'Polygonal',
      parsedPolygon: null,
      drawingOptions: {
        fillColor: '#4aff5c20',
        fillOpacity: 1,
        strokeWeight: 5,
        strokeColor: '#00b44d',
        clickable: true,
        draggable: true,
        editable: true, // TODO: make this work with wrapper func
        zIndex: 1,
      },
      serviceDrawingOptions: {
        fillColor: '#16653470',
        fillOpacity: 1,
        strokeWeight: 4,
        strokeColor: '#00b44d',
        clickable: false,
        draggable: false,
        editable: false, // TODO: make this work with wrapper func
        zIndex: 1,
      },
      allServiceArea: [],
      // places autocomplete
      location_name: '',
      theZoom: null,
    }
  },
  watch: {
    parsedPolygon() {
      if (this.parsedPolygon) {
        this.bindPolygonClickListener(this.parsedPolygon)
      }
    },
  },
  async created() {
    console.log('service = ', DropdownConfig.api.serviceAreaFleets)
    this.serviceAreaFleets = await this.$http
      .get(DropdownConfig.api.serviceAreaFleets)
      .then((res) => res.data.data)
      .catch((err) => console.log(err))
    console.log('service = ', this.serviceAreaFleets)

    this.serviceAreaSpeedLimits = await this.$http
      .get(DropdownConfig.api.serviceAreaSpeedLimits)
      .then((res) => res.data.data)
      .catch((err) => console.log(err))

    this.allServiceArea = await this.$http
      .get(ServiceAreaConfig.api.all)
      .then((res) => res.data.data)
      .catch((err) => console.log(err))
    console.log('allServiceArea', this.allServiceArea)
  },
  computed: {
    google: gmapApi,
    drawingType: function() {
      return [
        {
          id: 'CIRCLE',
          name: 'Circle',
        },
        {
          id: 'POLYGON',
          name: 'Polygon',
        },
      ]
    },
    isEditing: function() {
      // enabled status-switch by default
      return Object.keys(this.item).length > 1
    },
    titleText: function() {
      return this.isEditing ? 'Edit Service Area' : 'Add Service Area'
    },
    actionButtonText: function() {
      return this.isEditing ? 'Update' : 'Save'
    },
  },
  mounted() {
    EventBus.$on(ServiceAreaConfig.events.editingData, (item) => {
      console.log('item = ', {
        item,
      })
      console.log(this.isEditing)
      this.item = item
      if (this.item.existing_fleet) {
        const includesFleet = this.serviceAreaFleets.some((elem) => {
          return (
            JSON.stringify(this.item.existing_fleet) === JSON.stringify(elem)
          )
        })
        if (!includesFleet) {
          console.log(
            'Fleets',
            this.serviceAreaFleets,
            this.item.existing_fleet
          )
          console.log('------- PROPAGATING EXISTING FLEET -------- ')
          this.serviceAreaFleets.push(this.item.existing_fleet)
          console.log(
            'Fleets',
            this.serviceAreaFleets,
            this.item.existing_fleet
          )
        }
        delete this.item.existing_fleet
      }
      this.form = {
        ...this.item,
      }
      console.log(this.form)
      let filteredServicerArea
      if (this.isEditing) {
        filteredServicerArea = this.allServiceArea.filter(
          (area) => area.id !== this.item.id
        )
      } else {
        filteredServicerArea = this.allServiceArea
      }
      setTimeout(() => {
        this.drawServiceArea(filteredServicerArea)
      }, 5000)
    })
    this.getCurrentLocation()

    console.log('Map Ref', this.$refs.map)
  },
  updated: function() {
    var self = this
    this.$nextTick(function() {
      // Code that will run only after the
      // entire view has been re-rendered
      if (!self.$refs.map) return

      self.$refs.map.$mapPromise.then((map) => {
        console.log('Hitting promise with', map)
        self.map = map
        if (self.isEditing) {
          console.log('GOT POLYGON COORDS FROM SERVER', this.form.coords)
          const overlay = self.parseToOverlay(this.form.coords)
          self.setOverlayOnMap(overlay)
          self.setMapCenterToDrawingCenter(overlay, false)
        }
        self.initDrawingMode()
        // if (this.parsedPolygon) {
        //   this.google.maps.event.addListener(this.parsedPolygon, 'dragend', function(event){
        //     console.log('DRAGGED...', event)
        //   });
        //   this.google.maps.event.addListener(this.parsedPolygon, 'bounds_changed', function(event){
        //     console.log('Bound...', event)
        //   });
        // }
      })
    })
  },
  methods: {
    async getAddressData(data) {
      var lat = data.geometry.location.lat().toString()
      var lng = data.geometry.location.lng().toString()
      console.log('lat = ', lat, lng)
      this.center = {
        lat: parseFloat(lat),
        lng: parseFloat(lng),
      }
    },
    async onChangeFleet(event) {
      console.log(event)
      // this.serviceAreaFleets.forEach(element => {
      //   if (element.id == event) {
      //     console.log(this.serviceAreaFleets);
      //   }
      // });
      await this.$http
        .get(FleetConfig.api.single(event))
        .then((res) => {
          console.log('res Fleet= ', res.data)
          let data = res.data
          // todo: find out why updated() hook blocking the
          // rich select from updating
          this.form.speed_limit = data.geofence.speed_limit.id

          this.location_name = data.warehouse_name
          this.zoom = 16
          this.center = {
            lat: parseFloat(data.warehouse_lat),
            lng: parseFloat(data.warehouse_lon),
          }
        })
        .catch((err) => {
          console.log('err = ', err.message)
        })
    },
    goBack() {
      this.theZoom = null
      dispatchEvent(new Event(ServiceAreaConfig.events.sorToggle))
    },
    initDrawingMode() {
      if (this.drawingManager) {
        this.drawingManager.setMap(null)
      }
      this.drawingManager = new this.google.maps.drawing.DrawingManager({
        drawingMode: this.google.maps.drawing.OverlayType.POLYGON,
        drawingControl: true,
        drawingControlOptions: {
          position: this.google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [
            this.google.maps.drawing.OverlayType.POLYGON,
            this.google.maps.drawing.OverlayType.CIRCLE,
          ],
        },
        // markerOptions: {
        //   icon:
        //     "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png"
        // },
        circleOptions: this.drawingOptions,
        polygonOptions: this.drawingOptions,
      })
      console.log('Manager ', this.drawingManager)
      this.drawingManager.setMap(this.map)
      this.google.maps.event.addListener(
        this.drawingManager,
        'overlaycomplete',
        this.overlayCompleted
      )
      // this.google.maps.event.addListener(
      //   this.drawingManager,
      //   "polygoncomplete",
      //   function(polygon) {
      //   }
      // );
      this.google.maps.event.addListener(
        this.drawingManager,
        'circlecomplete',
        this.circleCompleted
      )
    },
    drawServiceArea(serviceAreas) {
      serviceAreas.forEach((area) => {
        const overlay = this.parseToOverlay(area.coords)

        // Get bounds
        const bounds = new this.google.maps.LatLngBounds()
        for (const path of overlay) {
          bounds.extend(path)
        }

        // draw polygon
        const parsedPolygon = new this.google.maps.Polygon({
          paths: overlay,
          ...this.serviceDrawingOptions,
        })
        parsedPolygon.setMap(this.map)
      })
    },
    circleCompleted(event) {
      console.log('CIRCLE ENDED')
      if (this.parsedPolygon) this.parsedPolygon.setMap(null)
      this.mapDrawingMode = 'Circular'
      this.form.draw_type = 'C'
      const coordStr = this.getCircleCoords(event)
      this.setDrawnCoords(coordStr, event)
    },
    overlayCompleted(event) {
      console.log(event)
      if (event.type == this.google.maps.drawing.OverlayType.POLYGON) {
        if (this.parsedPolygon) this.parsedPolygon.setMap(null)
        // this.bindPolygonClickListener(event.overlay);
        this.mapDrawingMode = 'Polygonal'
        this.form.draw_type = 'P'
        const coordStr = this.getPolygonCoords(event)
        this.setDrawnCoords(coordStr, event)
      }
    },
    bindPolygonClickListener(overlay) {
      console.log('Listener hit')
      var outerContext = this
      this.google.maps.event.addListener(overlay, 'mouseup', function(event) {
        console.log(event)
        console.log('Listener', overlay.getPath().getArray())
        const coordStr = outerContext.getCoordStrFromCoordArray(
          overlay.getPath().getArray()
        )
        // console.log("UPDATED FROM CUSTOM EVENT HANDLER", coordStr)
        outerContext.form.coords = coordStr
      })
    },
    getCircleCoords(circle) {
      var numPts = 20
      var path = []
      for (var k = 0; k < numPts; k++) {
        path.push(
          this.google.maps.geometry.spherical.computeOffset(
            circle.getCenter(),
            circle.getRadius(),
            (k * 360) / numPts
          )
        )
      }
      console.log('Circle Path', path)
      var dumpStr = ''
      for (var i = 0; i < path.length; i++) {
        console.log(path[i].lat())
        dumpStr += `{${path[i].lat()},${path[i].lng()}},`
      }
      console.log('Circle draw completed', dumpStr)
      return dumpStr
    },
    getCoordStrFromCoordArray(coordinatesArray, isNativePolygon = true) {
      var dumpStr = ''
      for (var item of coordinatesArray) {
        console.log(item)
        dumpStr += `{${isNativePolygon ? item.lat() : item.lat},${
          isNativePolygon ? item.lng() : item.lng
        }},`
      }
      console.log('Poly draw completed', dumpStr)
      return dumpStr
    },
    getPolygonCoords(polygon) {
      var coordinatesArray = polygon.overlay.getPath().getArray()
      return this.getCoordStrFromCoordArray(coordinatesArray, true)
    },
    setDrawnCoords(coords, sender) {
      this.form.coords = coords
      console.log(sender)
      if (this.parsedPolygon) {
        this.parsedPolygon.setMap(null)
      }
      // this.drawingManager.setMap(null);
      if (this.parsedPolygon !== sender) {
        sender.overlay ? sender.overlay.setMap(null) : sender.setMap(null)
      }
      // this.initDrawingMode();
      var that = this
      this.$nextTick(function() {
        const overlay = that.parseToOverlay(coords)
        that.setOverlayOnMap(overlay)
        // that.bindPolygonClickListener(sender.overlay);
        that.setMapCenterToDrawingCenter(overlay, true)
      })
    },
    parseToOverlay(coords) {
      if (!coords) return
      var coordsMorph = coords.replaceAll('{', '')
      coordsMorph = coordsMorph.replaceAll('}', '')
      var coordsArr = coordsMorph.split(',')
      if (!coordsArr.length % 2 === 0) {
        if (coordsArr[coordsArr.length - 1] == ',') {
          console.log('Hit pop')
          coordsArr.pop()
        }
      }
      // console.log("")
      var payload = []
      for (var i = 0; i < coordsArr.length; i = i + 2) {
        // console.log("lat", coordsArr[i])
        // console.log("lon", coordsArr[i+1])
        const lat = parseFloat(coordsArr[i])
        const lng = parseFloat(coordsArr[i + 1])
        if (isNaN(lat) || isNaN(lng)) continue
        payload.push({
          lat: lat,
          lng: lng,
        })
      }
      return payload
    },
    setOverlayOnMap(payload) {
      this.parsedPolygon = new this.google.maps.Polygon({
        paths: payload,
        ...this.drawingOptions,
      })
      this.parsedPolygon.setMap(this.map)
      console.log(payload)
      // console.log(this.parsedPolygon.getPath().getArray())
      console.log('SET ON MAP')
    },
    updatePolygonBounds(index, path, payload) {
      console.log('HIT UPDATE POLY...', payload[index])
      if (!this.parsedPolygon) return
      payload[index] = {
        lat: parseFloat(path.lat()),
        lng: parseFloat(path.lng()),
      }
      console.log('HIT UPDATED POLY...', {
        lat: parseFloat(path.lat()),
        lng: parseFloat(path.lng()),
      })
      // payload[index] = path
      const coordStr = this.getCoordStrFromCoordArray(payload, false)
      console.log('STORED COORDS', this.form.coords)
      console.log('DIFFED COORDS', coordStr)
      this.form.coords = coordStr
      console.log('STORED UPD', this.form.coords)
      // this.setDrawnCoords(coordStr, this.parsedPolygon)
    },
    setMapCenterToDrawingCenter(paths, panToCenter = true) {
      var bounds = new this.google.maps.LatLngBounds()
      for (const path of paths) {
        bounds.extend(path)
      }
      const newCenter = bounds.getCenter()
      console.log('NEW CENTER ', newCenter)
      if (panToCenter) {
        this.map.panTo(newCenter)
      } else {
        this.center.lat = newCenter.lat()
        this.center.lng = newCenter.lng()
      }
      //reset map zoom based on new poly (can't see whole poly on render if the poly is bigger then the current set zoom)
      this.map.fitBounds(bounds)
      // if (!this.parsedPolygon) return
      // const polyBounds = this.getPolygonBounds(this.parsedPolygon)
      // const newZoomLevel = this.getZoomLevelByBounds(this.map, polyBounds)
      // this.map.setZoom(newZoomLevel)
    },
    getCurrentLocation() {
      navigator.geolocation.getCurrentPosition((position) => {
        // console.log(position);
        this.center.lat = position.coords.latitude
        this.center.lng = position.coords.longitude
      })
    },
    // getPolygonBounds(polygon) {
    //   var bounds = new this.google.maps.LatLngBounds();
    //   var paths = polygon.getPaths();
    //   var path;
    //   for (var i = 0; i < paths.getLength(); i++) {
    //     path = paths.getAt(i);
    //     for (var ii = 0; ii < path.getLength(); ii++) {
    //       bounds.extend(path.getAt(ii));
    //     }
    //   }
    //   return bounds;
    // },
    // getZoomLevelByBounds(map, bounds) {
    //   var MAX_ZOOM = 20;
    //   var MIN_ZOOM = 3;

    //   var ne = map.getProjection().fromLatLngToPoint(bounds.getNorthEast());
    //   var sw = map.getProjection().fromLatLngToPoint(bounds.getSouthWest());

    //   var worldCoordWidth = Math.abs(ne.x - sw.x);
    //   var worldCoordHeight = Math.abs(ne.y - sw.y);

    //   //Fit padding in pixels
    //   var FIT_PAD = 40;

    //   for (var zoom = MAX_ZOOM; zoom >= MIN_ZOOM; --zoom) {
    //     if (
    //       worldCoordWidth * (1 << zoom) + 2 * FIT_PAD <
    //         this.map.getDiv().offsetWidth &&
    //       worldCoordHeight * (1 << zoom) + 2 * FIT_PAD <
    //         this.map.getDiv().offsetheight
    //     )
    //       return zoom;
    //   }
    //   return 0;
    // },
    checkValidPolygon(coordStr) {
      return coordStr.split(',').length > 2
    },
    submit: async function() {
      console.log('COORDS', this.form.coords)
      if (this.form.coords === undefined || this.form.coords === null) {
        this.isLoading = false
        alert('Please draw a valid geofence (Polygon/Circle)')
        // this.$notify(
        //   {
        //     group: "generic",
        //     type: "success",
        //     title: "Success",
        //     text: message
        //   },
        //   3000
        // );
        return
      } else {
        this.isLoading = false
      }
      if (!this.checkValidPolygon(this.form.coords)) {
        alert(
          'Please draw a valid polygon containing more than two coordinates'
        )
        return
      }
      this.$refs.submitButton.click()
    },
    confirmSubmit: async function() {
      this.isLoading = true
      console.log('Form Data', this.form)
      let method = this.isEditing ? 'PATCH' : 'POST'
      let url = this.isEditing
        ? ServiceAreaConfig.api.update(this.item.id)
        : ServiceAreaConfig.api.create
      let data = new xMan(this.form).toFormData()
      let message = this.isEditing
        ? 'Service Area updated successfully'
        : 'Service Area added successfully'

      // todo: remove logs
      console.log({
        url,
        ...this.form,
      })

      console.log('form = ', this.form)

      try {
        let response = await this.$http({
          method,
          url,
          data,
        })

        console.log('response = ', url, '  ', method, '  ')
        console.log('data = ', response)

        this.isLoading = false
        // Close the slideover
        dispatchEvent(new Event(ServiceAreaConfig.events.sorToggle))
        // Refetch the indexData
        dispatchEvent(new Event(ServiceAreaConfig.events.refresh))
        // Reset the form
        this.form = {}
        this.$notify(
          {
            group: 'generic',
            type: 'success',
            title: 'Success',
            text: message,
          },
          3000
        )
        // Resolved
        return response.status
      } catch (error) {
        // todo: remove logs
        console.log(error)
        // todo: show server errors
        this.isLoading = false
        // todo: handle server errors
        // this.errors.push("sadf");
        // Rejected
        // throw new Error(error.status);
      }
    },
    async onUpdatingPlace(data) {
      var lat = data.geometry.location.lat().toString()
      var lng = data.geometry.location.lng().toString()

      this.center = {
        lat: parseFloat(lat),
        lng: parseFloat(lng),
      }

      const overlay = this.parseToOverlay(this.current_coords)
      console.warn(overlay)
      // varying zoom
      this.theZoom = 18
      if (this.zoom === this.theZoom) {
        this.theZoom = 17
      } else {
        this.theZoom = 18
      }
      this.zoom = this.theZoom
    },
  },
}
</script>

<style scoped>
.toggle-button-active {
  border: 1px solid #1bca09;
  width: 100%;
  height: 140px;
  border-radius: 4px;
  padding-left: 20px;
  padding-right: 20px;
}
.toggle-button {
  width: 100%;
  height: 140px;
  border: 1px solid #cbd5e0;
  border-radius: 4px;
  padding-left: 20px;
  padding-right: 20px;
}
.toggle-title {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
.toggle-description {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 10px;
  @apply text-gray-600;
}
.back-button {
  width: 30px;
  height: 30px;
  border-radius: 100px;
  box-shadow: 1px 2px 3px 1px #d0caca;
  display: flex;
}
.back-button:hover {
  opacity: 0.8;
}
</style>
