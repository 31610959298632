var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('slide-over-right',{attrs:{"id":_vm.sorId,"full-width":true}},[_c('loading',{attrs:{"active":_vm.isLoading},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('div',{staticClass:"flex flex-wrap"},[_c('div',{staticClass:"w-full md:w-1/2"},[_c('div',{staticClass:"flex items-center pl-8 mt-6 md-max:pl-4"},[_c('div',{staticClass:"items-center justify-center cursor-pointer back-button",on:{"click":_vm.goBack}},[_c('i',{staticClass:"fas fa-arrow-left"})]),_c('h2',{staticClass:"ml-3 font-semibold text-gray-900 text-22px"},[_vm._v(" "+_vm._s(_vm.titleText)+" ")])]),(_vm.errors.length > 0)?_c('div',{staticClass:"pl-6 text-red-500"},[_vm._v(" Error returned from server ")]):_vm._e(),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"px-6",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.confirmSubmit)}}},[_c('div',{staticClass:"grid grid-cols-1 gap-2 mt-4 lg:grid-cols-2"},[_c('text-input',{attrs:{"rules":"required","label":"Area name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('ValidationProvider',{attrs:{"name":"Fleet","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":"Assigned Fleet *"}}),_c('t-rich-select',{attrs:{"name":"options","options":_vm.serviceAreaFleets,"valueAttribute":"id","textAttribute":"name","hideSearchBox":false,"variant":{ danger: !!errors[0] }},on:{"input":_vm.onChangeFleet},model:{value:(_vm.form.fleet),callback:function ($$v) {_vm.$set(_vm.form, "fleet", $$v)},expression:"form.fleet"}}),_c('div',{},[_c('input-error-item',{attrs:{"message":errors[0]}})],1)]}}],null,true)})],1),_c('PlacesAutocomplete',{attrs:{"label":"Search for a location","disabled":!!!_vm.form.fleet,"placeholder":!!_vm.form.fleet === false
                ? 'Select a fleet first'
                : 'Enter address / location'},on:{"update:place":_vm.onUpdatingPlace,"clear:place":function () { return (_vm.place = null); }}}),_c('div',{staticClass:"grid grid-cols-1 gap-2 mt-6 lg:grid-cols-2"},[_c('ValidationProvider',{attrs:{"name":"Speed Limit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":"Speed Limit *"}}),_c('t-rich-select',{attrs:{"name":"options","options":_vm.serviceAreaSpeedLimits,"valueAttribute":"id","textAttribute":"speed_choice","hideSearchBox":true,"variant":{ danger: !!errors[0] }},model:{value:(_vm.form.speed_limit),callback:function ($$v) {_vm.$set(_vm.form, "speed_limit", $$v)},expression:"form.speed_limit"}}),_c('div',{},[_c('input-error-item',{attrs:{"message":errors[0]}})],1)]}}],null,true)}),_c('text-input',{attrs:{"label":"Type","disabled":true},model:{value:(_vm.mapDrawingMode),callback:function ($$v) {_vm.mapDrawingMode=$$v},expression:"mapDrawingMode"}})],1),_c('div',{staticClass:"w-full"},[_c('input-label',{attrs:{"text":"Note"}}),_c('t-textarea',{attrs:{"name":"serviceAreaDescription"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('input-error-item')],1),_c('section',[_c('input-label',{staticClass:"my-2 font-bold ",attrs:{"text":"Vehicle Goes Outside Of Boundary Should"}}),_c('div',{staticClass:"grid grid-cols-1 gap-2 mt-4 lg:grid-cols-2"},[_c('div',{class:_vm.form.should_throttle == true
                    ? 'toggle-button-active flex items-center justify-center'
                    : 'toggle-button flex items-center justify-center'},[_c('div',{staticClass:"grid w-3/5"},[_c('span',{staticClass:"toggle-title"},[_vm._v("Throttle")])]),_c('div',{staticClass:"w-2/5"},[_c('div',{staticClass:"flex items-center"},[_c('t-toggle',{attrs:{"variant":"success","checked":!!_vm.form.should_throttle},model:{value:(_vm.form.should_throttle),callback:function ($$v) {_vm.$set(_vm.form, "should_throttle", $$v)},expression:"form.should_throttle"}}),_c('span',{staticClass:"ml-2 text-sm font-semibold"},[_vm._v(_vm._s(_vm.form.should_throttle ? 'ON' : 'OFF'))])],1)])]),_c('div',{class:_vm.form.should_trigger
                    ? 'toggle-button-active flex items-center justify-center'
                    : 'toggle-button flex items-center justify-center'},[_c('div',{staticClass:"grid w-3/5"},[_c('span',{staticClass:"toggle-title"},[_vm._v("Trigger Alarm")])]),_c('div',{staticClass:"w-2/5"},[_c('div',{staticClass:"flex items-center"},[_c('t-toggle',{attrs:{"variant":"success","checked":!!_vm.form.should_trigger},model:{value:(_vm.form.should_trigger),callback:function ($$v) {_vm.$set(_vm.form, "should_trigger", $$v)},expression:"form.should_trigger"}}),_c('span',{staticClass:"ml-2 text-sm font-semibold"},[_vm._v(_vm._s(_vm.form.should_trigger ? 'ON' : 'OFF'))])],1)])])])],1),_c('button',{ref:"submitButton",staticClass:"hidden",attrs:{"type":"submit"}},[_vm._v(" Save ")])],1)]}}])}),_c('div',{staticClass:"flex items-center justify-end pr-6 mt-6"},[_c('slide-over-right-trigger',{attrs:{"id":_vm.sorId}},[_c('anchor-button',{attrs:{"variant":"secondary"}},[_vm._v("Cancel")])],1),_c('t-button',{staticClass:"ml-3",attrs:{"type":"submit"},on:{"click":function($event){return _vm.submit()}}},[_vm._v("Save")])],1)],1),_c('div',{staticClass:"w-full mt-6 md:w-1/2 md:mt-0"},[_c('gmap-map',{ref:"map",staticStyle:{"width":"100%","height":"100vh"},attrs:{"zoom":_vm.zoom,"center":_vm.center,"map-type-id":"terrain"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }