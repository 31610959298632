<template>
  <div>
    <loading :active.sync="isLoading"></loading>
    <add-edit-service-area></add-edit-service-area>
    <div class="flex flex-wrap" v-if="service_area != null">
      <div
        :class="
          is_full
            ? 'w-0'
            : 'w-1/2 px-6 py-4 bg-white md-max:w-full md-max:px-4 flex-wrap'
        "
      >
        <div class="flex flex-wrap items-center">
          <div class="flex items-center w-2/3 pl-2 md-max:w-full md-max:pl-2">
            <div
              class="items-center justify-center mr-4 cursor-pointer back-button"
              @click="goBack"
            >
              <i class="fas fa-arrow-left"></i>
            </div>
            <span class="title">{{ titleText }}</span>
            <h3 class="pl-1 font-semibold text-gray-900 text-22px">
              Details (#234234)
            </h3>
          </div>
          <div
            class="flex items-center justify-end w-1/3 pl-2 md-max:w-full md-max:pl-0 md-max:pt-6"
          >
            <oto-edit-button @click="edit" />
          </div>
        </div>

        <div class="flex items-center my-12">
          <card-tax-stat
            title="Total Vehicles"
            :value="service_area.total_vehicles || 0"
            variant="orange"
            class="mr-3"
          />
          <card-tax-stat
            title="Out of Geofence"
            :value="service_area.vehicles_outside"
            variant="red"
            class="mr-3"
          />
        </div>

        <div class="flex items-center my-12">
          <div class="block w-1/2 pl-2">
            <span class="block detail-title">Service Area Name</span>
            <span class="block mt-4 detail-value">{{ service_area.name }}</span>
          </div>
          <div class="block w-1/2 pl-4">
            <span class="block detail-title">Assign Fleet</span>
            <span class="block mt-4 detail-value">{{
              service_area.fleet ? service_area.fleet.name : '--'
            }}</span>
          </div>
        </div>

        <div class="flex items-center my-12">
          <div class="block w-1/2 pl-2">
            <span class="block detail-title">Speed Limit</span>
            <span class="block mt-4 detail-value">{{
              service_area.speed_limit
                ? service_area.speed_limit.speed_choice
                : 'Low'
            }}</span>
          </div>
          <div class="block w-1/2 pl-4">
            <span class="block detail-title">Vehicle Speed Mode</span>
            <span class="block mt-4 detail-value"
              >{{
                service_area.speed_limit
                  ? service_area.speed_limit.speed_value
                  : '0'
              }}
              (kmp/h)</span
            >
          </div>
        </div>

        <div class="flex items-center my-12">
          <div class="block w-1/2 pl-2">
            <span class="block detail-title">Type</span>
            <span class="block mt-4 detail-value">{{
              service_area.draw_type == 'P' ? 'Polygon' : 'Circle'
            }}</span>
          </div>
          <div class="block w-1/2 pl-4">
            <span class="block detail-title">Service Area Note</span>
            <span class="block mt-4 detail-value">{{
              service_area.description ? service_area.description : ''
            }}</span>
          </div>
        </div>
        <input-label
          class="my-2 font-bold "
          text="Vehicle Goes Outside Of Boundary Should"
        />
        <div class="flex items-center md-max:flex-wrap">
          <div class="w-1/2 pl-2 pr-4 md-max:w-full md-max:px-0 md-max:py-3">
            <div
              :class="
                this.service_area.should_throttle == true
                  ? 'toggle-button-active flex items-center justify-center'
                  : 'toggle-button flex items-center justify-center'
              "
            >
              <div class="grid w-3/5">
                <span class="toggle-title">Throttle</span>
              </div>
              <div class="w-2/5">
                <div class="flex items-center">
                  <t-toggle
                    variant="success"
                    :checked="this.service_area.should_throttle"
                    readonly
                  />
                  <span class="ml-2 text-sm font-semibold">{{
                    this.service_area.should_throttle ? 'ON' : 'OFF'
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="w-1/2 pl-4 md-max:w-full md-max:pl-0">
            <div
              :class="
                this.service_area.should_trigger == true
                  ? 'toggle-button-active flex items-center justify-center'
                  : 'toggle-button flex items-center justify-center'
              "
            >
              <div class="grid w-3/5">
                <span class="toggle-title">Trigger Alarm</span>
              </div>
              <div class="w-2/5">
                <div class="flex items-center">
                  <t-toggle
                    variant="success"
                    :checked="this.service_area.should_trigger"
                    readonly
                  />
                  <span class="ml-2 text-sm font-semibold">{{
                    this.service_area.should_trigger ? 'ON' : 'OFF'
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        :class="is_full ? 'w-full relative' : 'w-1/2 relative md-max:w-full'"
      >
        <right-alert
          :avatar="getScooterImage"
          :vehicle="alertVehicle"
          :item_class="
            `flex h-10 mt-1 mb-1 border-t-2 border-gray-200 items-center`
          "
          v-if="infoAlertOpen"
        />
        <gmap-map
          ref="map"
          :zoom="zoom"
          :center="center"
          map-type-id="terrain"
          style="width: 100%; height: 100vh;"
          @click="toggleMap"
        >
          <gmap-marker
            :key="index"
            v-for="(vehicle, index) in vehicles"
            :position="getLatLng(vehicle.location)"
            :icon="{
              url: require(`@/assets/img/status/${getIconMap(
                vehicle
              )}_icon.png`),
              scaledSize: getNormalIcon(vehicle)
                ? { width: 63, height: 73 }
                : { width: 55, height: 65 },
            }"
            @mouseover="toggleMarker(vehicle, index)"
            @click="toggleAlert(vehicle)"
          >
          </gmap-marker>
          <gmap-info-window
            :options="infoOptions"
            :position="infoWindowPos"
            :opened="infoWinOpen"
            @closeclick="infoWinOpen = false"
          >
            <div v-html="infoContent"></div>
          </gmap-info-window>
        </gmap-map>
        <!-- <div class="full-map-view">
          <div
            class="items-center justify-center full-map-button hidden-class"
            @click="fullMap"
          >
            {{ !is_full ? 'Full Map View' : 'Original Map View' }}
          </div>
        </div> -->
        <div class="area-info">
          <div
            class="flex flex-wrap items-center justify-between px-4 area-body"
          >
            <round-tab
              v-for="(item, index) in bottom_tabs"
              :key="index"
              :type="item.type"
              :name="item.name"
              v-on:changeArea="changeArea"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ServiceAreaConfig } from '@/config/ServiceAreaConfig'
import { DropdownConfig } from '@/config/DropdownConfig'
import { VehicleConfig } from '@/config/VehicleConfig'
import { gmapApi } from 'vue2-google-maps'
import OtoEditButton from '@/components/ui/OtoEditButton.vue'
import RightAlert from '@/components/live-map/RightAlert'
import AddEditServiceArea from '@/views/geofence/service-area/AddEditServiceArea'
import { EventBus } from '@/utils/EventBus'
import CardTaxStat from '@/components/cards/TaxStat'
import RoundTab from '@/components/service-area/RoundTab.vue'

export default {
  name: 'ViewServiceArea',
  components: {
    OtoEditButton,
    RightAlert,
    AddEditServiceArea,
    CardTaxStat,
    RoundTab,
    InputLabel: () => import('@/components/form/InputLabel'),
  },
  data() {
    return {
      isLoading: false,
      serviceAreaFleets: [],
      serviceAreaSpeedLimits: [],
      item: {},
      form: {
        should_trigger: true,
        should_throttle: false,
        coords: null,
      },
      center: {
        lat: 10,
        lng: 10,
      },
      errors: [],
      sorId: ServiceAreaConfig.events.sorId,
      scrollwheel: true,
      zoom: 12,
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: true,
      streetViewControl: false,
      rotateControl: true,
      fullscreenControl: true,
      markers: [{}],
      edited: null,
      map: null,
      drawingManager: null,
      mapDrawingMode: 'Polygonal',
      parsedPolygon: null,
      drawingOptions: {
        fillColor: '#4aff5c20',
        fillOpacity: 1,
        strokeWeight: 2,
        strokeColor: '#00b44d',
        clickable: false,
        draggable: false,
        editable: false, // TODO: make this work with wrapper func
        zIndex: 1,
      },
      p_drawingOptions: {
        fillColor: 'rgba(30, 152, 254, 0.13)',
        fillOpacity: 1,
        strokeWeight: 2,
        strokeColor: '#00acff',
        clickable: false,
        draggable: false,
        editable: false, // TODO: make this work with wrapper func
        zIndex: 1,
      },
      r_drawingOptions: {
        fillColor: 'rgba(255, 0, 0, 0.13)',
        fillOpacity: 1,
        strokeWeight: 2,
        strokeColor: '#ff0000',
        clickable: false,
        draggable: false,
        editable: false, // TODO: make this work with wrapper func
        zIndex: 1,
      },
      s_drawingOptions: {
        fillColor: 'rgba(255, 186, 64, 0.13)',
        fillOpacity: 1,
        strokeWeight: 2,
        strokeColor: '#ff7c10',
        clickable: false,
        draggable: false,
        editable: false, // TODO: make this work with wrapper func
        zIndex: 1,
      },
      service_area: null,
      vehicles: [],
      child_area: [],
      infoContent: '',
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      alertVehicle: null,
      infoWinOpen: false,
      infoAlertOpen: false,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -70,
        },
      },
      type: '',
      is_full: false,
      polygons: [],
      bottom_tabs: [
        {
          type: 'service',
          name: 'Service Area',
        },
        {
          type: 'parking',
          name: 'Parking Area',
        },
        {
          type: 'restricted',
          name: 'Restricted Area',
        },
        {
          type: 'slow_zone',
          name: 'Slow Speed Area',
        },
      ],
    }
  },
  watch: {
    // parsedPolygon() {
    //   if (this.parsedPolygon) {
    //     this.bindPolygonClickListener(this.parsedPolygon);
    //   }
    // }
  },
  async created() {
    this.serviceAreaFleets = await this.$http
      .get(DropdownConfig.api.serviceAreaFleets)
      .then((res) => res.data.data)
      .catch((err) => console.log(err))
    console.log('service = ', this.serviceAreaFleets)

    this.serviceAreaSpeedLimits = await this.$http
      .get(DropdownConfig.api.serviceAreaSpeedLimits)
      .then((res) => res.data.data)
      .catch((err) => console.log(err))
  },
  computed: {
    google: gmapApi,
    drawingType: function() {
      return [
        {
          id: 'CIRCLE',
          name: 'Circle',
        },
        {
          id: 'POLYGON',
          name: 'Polygon',
        },
      ]
    },
    id() {
      return this.$route.params.id
    },
    getScooterImage() {
      return require('@/assets/img/scooter.png')
    },
    titleText: function() {
      return 'Service Area /'
    },
  },
  async mounted() {
    this.initFunc()
    window.addEventListener(ServiceAreaConfig.events.refresh, () => {
      this.initFunc()
    })
  },
  updated: function() {
    // var self = this;
    // this.$nextTick(function() {
    // });
  },
  methods: {
    edit: async function() {
      var serviceAreaDetails = await this.$http
        .get(ServiceAreaConfig.api.details(this.id))
        .then((res) => res.data)
        .catch((err) => console.log(err))
      if (serviceAreaDetails == undefined) return

      delete serviceAreaDetails.created_at
      delete serviceAreaDetails.updated_at
      delete serviceAreaDetails.created_by
      delete serviceAreaDetails.organization

      serviceAreaDetails.existing_fleet = serviceAreaDetails.fleet
      serviceAreaDetails.fleet = serviceAreaDetails.fleet
        ? serviceAreaDetails.fleet.id
        : null
      serviceAreaDetails.speed_limit = serviceAreaDetails.speed_limit.id

      EventBus.$emit(ServiceAreaConfig.events.editingData, serviceAreaDetails)
      dispatchEvent(new Event(ServiceAreaConfig.events.sorToggle))
    },

    fullMap() {
      this.is_full = !this.is_full
    },

    goBack() {
      this.$router.push('/geofence/service-areas')
    },

    async initFunc() {
      this.isLoading = true
      await this.$http
        .get(ServiceAreaConfig.api.details(this.id))
        .then((res) => {
          console.log('res = ', res.data)
          this.service_area = res.data
        })
        .catch((err) => {
          console.log('vehicle-header-err', err)
          this.isLoading = false
        })
      this.vehicles = await this.$http
        .get(
          VehicleConfig.api.index + `?geofence=${this.service_area.id}&livemap`
        )
        .then((res) => res.data.data)
        .catch((err) => console.log(err))
      console.log(this.id)
      this.child_area = await this.$http
        .get(ServiceAreaConfig.api.child(this.id))
        .then((res) => res.data.data)
        .catch((err) => console.log(err))
      this.isLoading = false
      this.initDraw()
    },

    initDraw() {
      var self = this
      setTimeout(() => {
        console.log('updated')
        if (!self.$refs.map) return
        console.log('child = ', this.child_area)
        self.$refs.map.$mapPromise.then((map) => {
          console.log('Hitting promise with', map)
          self.map = map
          if (self.service_area != null) {
            console.log(
              'GOT POLYGON COORDS FROM SERVER',
              this.service_area.coords
            )
            const overlay = self.parseToOverlay(this.service_area.coords)
            self.setOverlayOnMap(overlay, 'service')
            self.setMapCenterToDrawingCenter(overlay, false)
          }

          self.child_area.parking_areas.forEach((item) => {
            const overlay = self.parseToOverlay(item.coords)
            self.setOverlayOnMap(overlay, 'parking')
            //self.setMapCenterToDrawingCenter(overlay, false);
          })

          self.child_area.restricted_areas.forEach((item) => {
            const overlay = self.parseToOverlay(item.coords)
            self.setOverlayOnMap(overlay, 'restricted')
            //self.setMapCenterToDrawingCenter(overlay, false);
          })

          self.child_area.slow_zone_areas.forEach((item) => {
            const overlay = self.parseToOverlay(item.coords)
            self.setOverlayOnMap(overlay, 'slow_zone')
            //self.setMapCenterToDrawingCenter(overlay, false);
          })
        })
      }, 1000)
    },

    changeArea(type) {
      this.polygons.forEach((item) => {
        if (item) item.setMap(null)
      })

      if (type == this.type) {
        this.type = ''
        this.initDraw()
        return
      } else {
        this.type = type
      }
      this.type = type
      if (!this.$refs.map) return

      // this.polygons.forEach(ite)

      this.$refs.map.$mapPromise.then((map) => {
        this.map = map
        if (type == 'service') {
          const overlay = this.parseToOverlay(this.service_area.coords)
          this.setOverlayOnMap(overlay, 'service')
          this.setMapCenterToDrawingCenter(overlay, false)
        } else if (type == 'parking') {
          this.child_area.parking_areas.forEach((item) => {
            const overlay = this.parseToOverlay(item.coords)
            this.setOverlayOnMap(overlay, 'parking')
            this.setMapCenterToDrawingCenter(overlay, false)
          })
        } else if (type == 'restricted') {
          this.child_area.restricted_areas.forEach((item) => {
            const overlay = this.parseToOverlay(item.coords)
            this.setOverlayOnMap(overlay, 'restricted')
            this.setMapCenterToDrawingCenter(overlay, false)
          })
        } else if (type == 'slowzone') {
          this.child_area.slow_zone_areas.forEach((item) => {
            const overlay = this.parseToOverlay(item.coords)
            this.setOverlayOnMap(overlay, 'slow_zone')
            this.setMapCenterToDrawingCenter(overlay, false)
          })
        } else if (type == '') {
          this.init()
        }
      })
    },

    getIconMap(vehicle) {
      if (vehicle.status_flags) {
        let data = vehicle.status_flags
        if (data.is_charging) {
          return 'is_charging'
        } else if (data.is_on_ride) {
          return 'is_on_ride'
        } else if (data.is_parking) {
          return 'is_parking'
        } else if (data.is_reserved) {
          return 'is_reserved'
        }
      }
      if (vehicle.general_flags) {
        let data = vehicle.general_flags
        if (data.geo_fence_alert) {
          return 'geo_fence_alert'
        } else if (data.iot_fault) {
          return 'iot_fault'
        } else if (data.missing) {
          return 'missing'
        } else if (data.low_battery) {
          return 'low_battery'
        }
      }
      if (vehicle.operational_flags) {
        let data = vehicle.operational_flags
        if (data.charging_pick) {
          return 'charging_pick'
        } else if (data.maintainance) {
          return 'maintainance'
        } else if (data.rebalance) {
          return 'rebalance'
        }
      }
      if (vehicle.lock) {
        let data = vehicle.lock
        if (Number(data.power_level) >= 74) {
          return 'full'
        } else if (
          Number(data.power_level) >= 50 &&
          Number(data.power_level) < 74
        ) {
          return 'normal'
        } else if (
          Number(data.power_level) >= 25 &&
          Number(data.power_level) < 50
        ) {
          return 'medium'
        } else if (
          Number(data.power_level) >= 5 &&
          Number(data.power_level) < 25
        ) {
          return 'low'
        } else if (
          Number(data.power_level) > 0 &&
          Number(data.power_level) < 5
        ) {
          return 'critical'
        } else if (Number(data.power_level) == 0) {
          return 'empty'
        }
      }
      return 'full'
    },

    getLatLng(position) {
      if (position == '' || position == null)
        return {
          lat: 0,
          lng: 0,
        }
      let lat = Number(position.split(',')[0])
      let lng = Number(position.split(',')[1])
      return {
        lat: lat,
        lng: lng,
      }
    },

    toggleMap() {
      this.infoWinOpen = false
      this.infoAlertOpen = false
    },

    toggleAlert(vehicle) {
      this.infoWinOpen = false
      this.infoAlertOpen = true
      this.alertVehicle = vehicle

      if (vehicle.location != null) {
        this.center = this.getLatLng(vehicle.location)
        this.zoom = 15
      }

      // this.$http.get(VehicleConfig.api.single(vehicle.id)).then(res => {
      //   console.log('res = ' , res.data)
      //   // this.infoAlertOpen = true;
      //   // this.alertVehicle = res.data
      // }).catch(err => {
      //   console.log('err = ' , err)
      //   this.infoAlertOpen = false;
      // })
    },

    toggleMarker(vehicle, idx) {
      this.infoWindowPos = this.getLatLng(vehicle.location)
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen
      } else {
        this.infoWinOpen = true
        this.currentMidx = idx
      }
      this.infoContent = this.getInfoWindowContent(vehicle)
    },

    showAlert(item) {
      console.log('alert')
      if (item.location != null) {
        this.center = this.getLatLng(item.location)
        this.zoom = 15
      } else {
        this.zoom = 2
      }
      this.infoWinOpen = false
      this.infoAlertOpen = true
      this.alertVehicle = item
    },

    getInfoWindowContent: function(item) {
      return `
        <div class="card">
          <div class="card-content">
            <div class="flex items-center h-6 pl-2">
              <span class="card-title">Vehicle Name:</span>
              <span class="ml-2 card-text">${
                item.name == undefined ? '' : item.name
              }</span>
            </div>

            <div class="flex items-center h-6 pl-2 mt-1">
              <span class="card-title">IMIE:</span>
              <span class="ml-2 card-text">${item.lock.lock_id}</span>
            </div>

            <div class="flex items-center h-6 pl-2 mt-1">
              <span class="card-title">QR:</span>
              <span class="ml-2 card-text">${item.lock.qr_code}</span>
            </div>

            <div class="flex items-center h-6 pl-2 mt-1">
              <span class="card-title">Battery:</span>
              <span class="ml-2 card-text">${item.lock.power_level}</span>
            </div>

            <div class="flex items-center h-6 pl-2 mt-1">
              <span class="card-title">Latitude:</span>
              <span class="ml-2 card-text">${
                this.getLatLng(item.location).lat
              }</span>
            </div>

            <div class="flex items-center h-6 pl-2">
              <span class="card-title">Longtitude:</span>
              <span class="ml-2 card-text">${
                this.getLatLng(item.location).lng
              }</span>
            </div>
          </div>
        </div>
      `
    },

    parseToOverlay(coords) {
      if (!coords) return
      var coordsMorph = coords.replaceAll('{', '')
      coordsMorph = coordsMorph.replaceAll('}', '')
      var coordsArr = coordsMorph.split(',')
      if (!coordsArr.length % 2 === 0) {
        if (coordsArr[coordsArr.length - 1] == ',') {
          console.log('Hit pop')
          coordsArr.pop()
        }
      }
      // console.log("")
      var payload = []
      for (var i = 0; i < coordsArr.length; i = i + 2) {
        // console.log("lat", coordsArr[i])
        // console.log("lon", coordsArr[i+1])
        const lat = parseFloat(coordsArr[i])
        const lng = parseFloat(coordsArr[i + 1])
        if (isNaN(lat) || isNaN(lng)) continue
        payload.push({
          lat: lat,
          lng: lng,
        })
      }
      return payload
    },

    setOverlayOnMap(payload, type) {
      var options = {}
      if (type == 'service') {
        options = this.drawingOptions
      } else if (type == 'parking') {
        options = this.p_drawingOptions
      } else if (type == 'restricted') {
        options = this.r_drawingOptions
      } else if (type == 'slow_zone') {
        options = this.s_drawingOptions
      }
      this.parsedPolygon = new this.google.maps.Polygon({
        paths: payload,
        ...options,
      })
      this.parsedPolygon.setMap(this.map)

      this.polygons.push(this.parsedPolygon)

      console.log(payload)
      console.log('SET ON MAP')
    },

    setMapCenterToDrawingCenter(paths, panToCenter = true) {
      var bounds = new this.google.maps.LatLngBounds()
      for (const path of paths) {
        bounds.extend(path)
      }
      const newCenter = bounds.getCenter()
      console.log('NEW CENTER ', newCenter)
      if (panToCenter) {
        this.map.panTo(newCenter)
      } else {
        this.center.lat = newCenter.lat()
        this.center.lng = newCenter.lng()
      }
      this.map.fitBounds(bounds)
    },

    getCurrentLocation() {
      navigator.geolocation.getCurrentPosition((position) => {
        // console.log(position);
        this.center.lat = position.coords.latitude
        this.center.lng = position.coords.longitude
      })
    },

    getNormalIcon(vehicle) {
      if (vehicle.status_flags) {
        let data = vehicle.status_flags
        if (data.is_charging) {
          return false
        } else if (data.is_on_ride) {
          return false
        } else if (data.is_parking) {
          return false
        } else if (data.is_reserved) {
          return false
        }
      }
      if (vehicle.general_flags) {
        let data = vehicle.general_flags
        if (data.geo_fence_alert) {
          return false
        } else if (data.iot_fault) {
          return false
        } else if (data.missing) {
          return false
        } else if (data.low_battery) {
          return false
        }
      }
      if (vehicle.operational_flags) {
        let data = vehicle.operational_flags
        if (data.charging_pick) {
          return false
        } else if (data.maintainance) {
          return false
        } else if (data.rebalance) {
          return false
        }
      }
      return true
    },
  },
}
</script>

<style lang="scss" scoped>
.feature-item {
  display: flex;
  justify-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e2e8f0;
  padding: 4px 0; // 15px 0?

  & .left {
    width: 65%;
    padding-right: 0.5rem;
    font-size: 0.875rem;
  }

  & .right {
    width: 35%;
    padding-left: 0.5rem;
  }
}
.toggle-button-active {
  border: 1px solid #1bca09;
  width: 100%;
  height: 140px;
  border-radius: 4px;
  padding-left: 20px;
  padding-right: 20px;
}
.toggle-button {
  width: 100%;
  height: 140px;
  border: 1px solid #cbd5e0;
  border-radius: 4px;
  padding-left: 20px;
  padding-right: 20px;
}
.toggle-title {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
.toggle-description {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 10px;
  @apply text-gray-600;
}
.detail-title {
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
.detail-value {
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #787881;
}
.title {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9393a2;
}
.area-info {
  position: absolute;
  bottom: 15px;
  width: 100%;
  height: 40px;
  display: flex;
  .area-body {
    width: 85%;
    height: 100%;
    margin: auto;
    background: white;
    border-radius: 5px;
    box-shadow: 1px 1px 6px 0px #757575bf;
  }
}
.full-map-view {
  position: absolute;
  width: 100%;
  bottom: 80px;
  display: flex;
  .full-map-button {
    margin: auto;
    width: 150px;
    background: white;
    height: 40px;
    margin-left: 30px;
    border-radius: 5px;
    display: flex;
    box-shadow: 1px 1px 6px 0px #757575bf;
    cursor: pointer;

    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #21212a;
  }
}

@media (max-width: 990px) {
  .area-info {
    bottom: 20px;
    width: 100%;
    height: 160px;
    display: block;
    .area-body {
      display: block;
    }
  }
  .hidden-class {
    display: none !important;
  }
}
</style>
